<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      sort-by="title"
      class="elevation-1"
      loading-text="Loading... Please wait"
      :search="search"
      :custom-filter="filterText"
    >
      <template v-slot:top>
        <v-toolbar flat class="pt-2">
          <v-col cols="8" md="6" sm="6" class="mt-5">
            <v-toolbar-title
              classs="error--text text-uppercase font-weight-bold text--darken-1 justify-center"
            >
              <v-icon class="mr-2 mt-n1" color="error"
                >mdi-comment-quote</v-icon
              >Quotations
            </v-toolbar-title>
            <p class="grey--text ml-8 mt-n2">List of all received quotations</p>
            <v-divider class="mx-4" inset vertical></v-divider>
          </v-col>
          <v-col cols="5" md="6" sm="6" class="mt-5" align="right">
            <v-text-field
              label="Search"
              v-model="search"
              append-icon="mdi-magnify"
              dense
              small
              color="primary"
              outlined
              class="my-auto"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-progress-linear
          indeterminate
          color="primary"
          v-if="loading"
        ></v-progress-linear>
        <v-divider></v-divider>
      </template>

      <template v-slot:[`item.quotation`]="{ item }">
        <div v-html="item.quotation"></div>
      </template>
      
      <template v-slot:[`item.status`]="{ item }">
        <v-chip
          v-if="item.status.slug == 'awarded'"
          class="ma-2" 
          color="success"
          text-color="white"
        >
          <v-avatar left>
            <v-icon>mdi-checkbox-marked-circle</v-icon>
          </v-avatar>
          {{ item.status.name }}
        </v-chip>

        <v-chip
          v-if="item.status.slug == 'pending'" 
          class="ma-2"
          color="primary"
          text-color="white"
        >
          <v-avatar left>
            <v-icon>mdi-account-clock-outline</v-icon>
          </v-avatar>
          {{ item.status.name }}
        </v-chip>

        <v-chip
          v-if="item.status.slug == 'rejected'" 
          class="ma-2"
          color="grey"
          text-color="white"
        >
          <v-avatar left>
            <v-icon>mdi-close-circle</v-icon>
          </v-avatar>
          {{ item.status.name }}
        </v-chip>

        <v-chip
          v-if="item.status.slug == 'delivered'" 
          class="ma-2"
          color="warning"
          text-color="white"
        >
          <v-avatar left>
            <v-icon>mdi-truck-delivery-outline</v-icon>
          </v-avatar>
          {{ item.status.name }}
        </v-chip>

        <v-chip
          v-if="item.status.slug == 'confirmed'" 
          class="ma-2"
          color="black"
          text-color="white"
        >
          <v-avatar left>
            <v-icon>mdi-credit-card-outline</v-icon>
          </v-avatar>
          {{ item.status.name }}
        </v-chip>

        <v-chip
          v-if="item.status.slug == 'lpo-sent'" 
          class="ma-2"
          color="indigo"
          text-color="white"
        >
          <v-avatar left>
            <v-icon>mdi-receipt</v-icon>
          </v-avatar>
          {{ item.status.name }}
        </v-chip>

        <v-chip
          v-if="item.status.slug == 'invoice-sent'" 
          class="ma-2"
          color="deep-orange darken-2"
          text-color="white"
        >
          <v-avatar left>
            <v-icon>mdi-receipt</v-icon>
          </v-avatar>
          {{ item.status.name }}
        </v-chip>

        <v-chip
          v-if="item.status.slug == 'invoice-confirmed'" 
          class="ma-2"
          color="error darken-2"
          text-color="white"
        >
          <v-avatar left>
            <v-icon>mdi-receipt</v-icon>
          </v-avatar>
          {{ item.status.name }}
        </v-chip>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-row align="center" justify="space-around">
          <div class="my-1">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <span class="d-inline-block mb-1" v-bind="attrs" v-on="on">
                  <v-btn
                    color="primary"
                    class="mx-1"
                    fab
                    x-small
                    dark
                    @click="viewItemModal(item)"
                  >
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                </span>
              </template>
              <span>View Quotation</span>
            </v-tooltip>

            <v-tooltip top v-if="checkStatus(item)">
              <template v-slot:activator="{ on, attrs }">
                <span class="d-inline-block mb-1" v-bind="attrs" v-on="on">
                  <v-btn 
                    color="success" 
                    class="mx-1" 
                    fab 
                    x-small 
                    dark 
                    @click="approveItem(item)">
                    <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
                  </v-btn>
                </span>
              </template>
              <span>Award Quotation</span>
            </v-tooltip>

            <v-tooltip top v-if="checkStatus(item)">
              <template v-slot:activator="{ on, attrs }">
                <span class="d-inline-block mb-1" v-bind="attrs" v-on="on">
                  <v-btn 
                    color="warning" 
                    class="mx-1" 
                    fab 
                    x-small 
                    dark 
                    @click="rejectItem(item)">
                    <v-icon>mdi-close-circle-outline</v-icon>
                  </v-btn>
                </span>
              </template>
              <span>Reject Quotation</span>
            </v-tooltip>

            <v-tooltip top v-if="item.status.slug == 'delivered'">
              <template v-slot:activator="{ on, attrs }">
                <span class="d-inline-block mb-1" v-bind="attrs" v-on="on">
                  <v-btn 
                    color="black" 
                    class="mx-1" 
                    fab 
                    x-small 
                    dark 
                    @click="confirmItem(item)">
                    <v-icon>mdi-truck-delivery-outline</v-icon>
                  </v-btn>
                </span>
              </template>
              <span>Confirm Delivery</span>
            </v-tooltip>

            <v-tooltip top v-if="item.status.slug == 'awarded'">
              <template v-slot:activator="{ on, attrs }">
                <span class="d-inline-block mb-1" v-bind="attrs" v-on="on">
                  <v-btn
                    color="indigo"
                    class="mx-1"
                    fab
                    x-small
                    dark
                    @click="generateLPO"
                  >
                    <v-icon>mdi-receipt</v-icon>
                  </v-btn>
                </span>
              </template>
              <span>Generate LPO</span>
            </v-tooltip>

            <v-tooltip top v-if="item.status.slug == 'invoice-sent'">
              <template v-slot:activator="{ on, attrs }">
                <span class="d-inline-block mb-1" v-bind="attrs" v-on="on">
                  <v-btn
                    color="black"
                    class="mx-1"
                    fab
                    x-small
                    dark
                    @click="generateInvoiceModal(item)"
                  >
                    <v-icon>mdi-receipt</v-icon>
                  </v-btn>
                </span>
              </template>
              <span>View Invoice</span>
            </v-tooltip>
          </div>
        </v-row>
      </template>
    </v-data-table>

    <div class="text-center">
      <v-dialog v-model="dialog_view" width="750">
        <v-card>
          <v-card-title class="headline text--white lighten-2">
            View Quotation
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12" class="my-n2" v-if="editedItem.requisition">
                  <v-text-field
                    v-model="editedItem.requisition.title"
                    label="Requisition Title"
                    :rules="rules.required"
                    color="grey"
                    dense
                    outlined
                    class="mb-n3"
                    readonly
                  >
                    <template v-slot:label>
                      <span class="input__label"
                        >Requisition Title
                        <v-icon small color="error" class="mt-n2"
                          >mdi-star-outline</v-icon
                        ></span
                      >
                    </template>
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="12" class="my-n2" v-if="editedItem.supplier">
                  <v-text-field
                    v-model="editedItem.supplier.name"
                    label="Supplier Name"
                    :rules="rules.required"
                    color="grey"
                    dense
                    outlined
                    class="mb-n3"
                    readonly
                  >
                    <template v-slot:label>
                      <span class="input__label"
                        >Supplier Name
                        <v-icon small color="error" class="mt-n2"
                          >mdi-star-outline</v-icon
                        ></span
                      >
                    </template>
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="12" class="my-2" v-if="editedItem.quotation">
                  <label>Quotation Details</label>
                  <ckeditor
                    class="w-full"
                    tag-name="textarea"
                    ref="cktext"
                    :editor="editor"
                    v-model="editedItem.quotation"
                    shouldnotGroupWhenFull="true"
                    :config="editorConfig2"
                    disabled
                  ></ckeditor>
                </v-col>

                <v-col cols="12" sm="12" md="12" class="my-2">
                  <h3>Quotation Items</h3>
                  
                  <template>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              #
                            </th>
                            <th class="text-left">
                              Name
                            </th>
                            <th class="text-left">
                              Quantity
                            </th>
                            <th class="text-left">
                              Price
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(quotation_item, i) in editedItem.items"
                            :key="i"
                          >
                            <td data-label="No.">{{ i + 1 }}</td>
                            <td>{{ quotation_item.requisition_item.name }}</td>
                            <td>{{ quotation_item.requisition_item.quantity }}</td>
                            <td>{{ quotation_item.price }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </template>
                </v-col>

                <v-col cols="12" sm="12" md="12" class="my-2">
                  <v-row align="center">
                    <div v-for="(item, i) in documents" :key="i">
                      <v-col
                        cols="12"
                        class="mt-n2"
                      >
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <span class="d-inline-block mb-1" v-bind="attrs" v-on="on">
                              <a
                                :href="item.content"
                                target="_blank"
                                class="d-block primary--text text--darken-1 text-decoration-none"
                              >
                                <v-icon x-large color="primary"
                                  >mdi-arrow-down-bold-circle</v-icon
                                >{{ truncate(item.document.name, 37) }}
                              </a>
                            </span>
                          </template>
                          <span
                            >Click to download the uploaded :
                            {{ truncate(item.document.name, 30) }}</span
                          >
                        </v-tooltip>
                      </v-col>
                    </div>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog_view = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <v-dialog v-model="dialog_approve" max-width="750px">
      <v-card>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-title>
            <span class="headline error--text text--darken-1">Approve Quotation</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12" class="my-n2" v-if="editedItem.requisition">
                  <v-text-field
                    v-model="editedItem.requisition.title"
                    label="Requisition Title"
                    :rules="rules.required"
                    color="grey"
                    dense
                    outlined
                    class="mb-n3"
                    readonly
                  >
                    <template v-slot:label>
                      <span class="input__label"
                        >Requisition Title
                        <v-icon small color="error" class="mt-n2"
                          >mdi-star-outline</v-icon
                        ></span
                      >
                    </template>
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="12" class="my-n2" v-if="editedItem.supplier">
                  <v-text-field
                    v-model="editedItem.supplier.name"
                    label="Supplier Name"
                    :rules="rules.required"
                    color="grey"
                    dense
                    outlined
                    class="mb-n3"
                    readonly
                  >
                    <template v-slot:label>
                      <span class="input__label"
                        >Supplier Name
                        <v-icon small color="error" class="mt-n2"
                          >mdi-star-outline</v-icon
                        ></span
                      >
                    </template>
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="12" class="my-2" v-if="editedItem.quotation">
                  <label>Quotation Details</label>
                  <ckeditor
                    class="w-full"
                    tag-name="textarea"
                    ref="cktext"
                    :editor="editor"
                    v-model="editedItem.quotation"
                    shouldnotGroupWhenFull="true,"
                    :config="editorConfig"
                    disabled
                  ></ckeditor>
                </v-col>

                <v-col cols="12" sm="12" md="12" class="my-2">
                  <h3>Quotation Items</h3>
                  
                  <template>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              #
                            </th>
                            <th class="text-left">
                              Name
                            </th>
                            <th class="text-left">
                              Quantity
                            </th>
                            <th class="text-left">
                              Price
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(quotation_item, i) in editedItem.items"
                            :key="i"
                          >
                            <td data-label="No.">{{ i + 1 }}</td>
                            <td>{{ quotation_item.requisition_item.name }}</td>
                            <td>{{ quotation_item.requisition_item.quantity }}</td>
                            <td>{{ quotation_item.price }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </template>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              class="text-capitalize mx-1"
              dark
              @click="dialog_approve = false"
            >
              Close <v-icon small>mdi-close</v-icon>
            </v-btn>
            <v-btn
              color="success"
              dark
              class="text-capitalize mx-1"
              :loading="loading"
              @click="approveQuotation"
            >
              Award <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_reject" max-width="750px">
      <v-card>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-title>
          <span class="headline error--text text--darken-1">Reject Quotation</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12" class="my-n2" v-if="editedItem.requisition">
                  <v-text-field
                    v-model="editedItem.requisition.title"
                    label="Requisition Title"
                    :rules="rules.required"
                    color="grey"
                    dense
                    outlined
                    class="mb-n3"
                    readonly
                  >
                    <template v-slot:label>
                      <span class="input__label"
                        >Requisition Title
                        <v-icon small color="error" class="mt-n2"
                          >mdi-star-outline</v-icon
                        ></span
                      >
                    </template>
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="12" class="my-n2" v-if="editedItem.supplier">
                  <v-text-field
                    v-model="editedItem.supplier.name"
                    label="Supplier Name"
                    :rules="rules.required"
                    color="grey"
                    dense
                    outlined
                    class="mb-n3"
                    readonly
                  >
                    <template v-slot:label>
                      <span class="input__label"
                        >Supplier Name
                        <v-icon small color="error" class="mt-n2"
                          >mdi-star-outline</v-icon
                        ></span
                      >
                    </template>
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="12" class="my-2" v-if="editedItem.quotation">
                  <label>Quotation Details</label>
                  <ckeditor
                    class="w-full"
                    tag-name="textarea"
                    ref="cktext"
                    :editor="editor"
                    v-model="editedItem.quotation"
                    shouldnotGroupWhenFull="true,"
                    :config="editorConfig"
                    disabled
                  ></ckeditor>
                </v-col>

                <v-col cols="12" sm="12" md="12" class="my-2">
                  <h3>Quotation Items</h3>
                  
                  <template>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              #
                            </th>
                            <th class="text-left">
                              Name
                            </th>
                            <th class="text-left">
                              Quantity
                            </th>
                            <th class="text-left">
                              Price
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(quotation_item, i) in editedItem.items"
                            :key="i"
                          >
                            <td data-label="No.">{{ i + 1 }}</td>
                            <td>{{ quotation_item.requisition_item.name }}</td>
                            <td>{{ quotation_item.requisition_item.quantity }}</td>
                            <td>{{ quotation_item.price }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </template>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              class="text-capitalize mx-1"
              dark
              @click="dialog_reject = false"
            >
              Close <v-icon small>mdi-close</v-icon>
            </v-btn>
            <v-btn
              color="warning darken-1"
              class="text-capitalize mx-1"
              dark
              @click="rejectQuotation"
            >
              Reject <v-icon small>mdi-close-circle-outline</v-icon>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_confirm" max-width="750px">
      <v-card>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-title>
            <span class="headline error--text text--darken-1">Confirm Delivery</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12" class="my-n2" v-if="editedItem.requisition">
                  <v-text-field
                    v-model="editedItem.requisition.title"
                    label="Requisition Title"
                    :rules="rules.required"
                    color="grey"
                    dense
                    outlined
                    class="mb-n3"
                    readonly
                  >
                    <template v-slot:label>
                      <span class="input__label"
                        >Requisition Title
                        <v-icon small color="error" class="mt-n2"
                          >mdi-star-outline</v-icon
                        ></span
                      >
                    </template>
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="12" class="my-n2" v-if="editedItem.supplier">
                  <v-text-field
                    v-model="editedItem.supplier.name"
                    label="Supplier Name"
                    :rules="rules.required"
                    color="grey"
                    dense
                    outlined
                    class="mb-n3"
                    readonly
                  >
                    <template v-slot:label>
                      <span class="input__label"
                        >Supplier Name
                        <v-icon small color="error" class="mt-n2"
                          >mdi-star-outline</v-icon
                        ></span
                      >
                    </template>
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="12" class="my-2" v-if="editedItem.quotation">
                  <label>Quotation Details</label>
                  <ckeditor
                    class="w-full"
                    tag-name="textarea"
                    ref="cktext"
                    :editor="editor"
                    v-model="editedItem.quotation"
                    shouldnotGroupWhenFull="true,"
                    :config="editorConfig"
                    disabled
                  ></ckeditor>
                </v-col>

                <v-col cols="12" sm="12" md="12" class="my-n2">
                  <v-file-input
                    v-model="editedItem.goods_file"
                    color="grey"
                    append-icon="mdi-file-upload"
                    prepend-icon=""
                    outlined
                    dense
                    @change="handleReceivedNoteUpload"
                  >
                    <template v-slot:label>
                      <span class="input__label"
                        >Attach Goods Received Note
                        <v-icon small color="error" class="mt-n2"
                          >mdi-star-outline</v-icon
                        ></span
                      >
                    </template>
                  </v-file-input>
                </v-col>

                <v-col cols="12" sm="12" md="12" class="my-n2">
                  <v-file-input
                    v-model="editedItem.voucher_file"
                    color="grey"
                    append-icon="mdi-file-upload"
                    prepend-icon=""
                    outlined
                    dense
                    @change="handleVoucherUpload"
                  >
                    <template v-slot:label>
                      <span class="input__label"
                        >Attach Payment Voucher
                        <v-icon small color="error" class="mt-n2"
                          >mdi-star-outline</v-icon
                        ></span
                      >
                    </template>
                  </v-file-input>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              class="text-capitalize mx-1"
              dark
              @click="dialog_confirm = false"
            >
              Close <v-icon small>mdi-close</v-icon>
            </v-btn>
            <v-btn
              color="success"
              dark
              class="text-capitalize mx-1"
              :loading="loading"
              @click="confirmDelivery"
            >
              Confirm <v-icon>mdi-delivery-truck</v-icon>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <div class="text-center">
      <v-dialog v-model="dialogGenerateInvoice" max-width="950px" class="pa-6">
        <v-card>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-toolbar
              dark
              color="primary"
            >
              <v-btn
                icon
                dark
                @click="dialogGenerateInvoice = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>View Invoice</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text>
              <v-container>
                  <v-col cols="6" md="6">
                    <v-btn
                      color="primary darken-1"
                      class="mx-1"
                      fab
                      x-small
                      dark
                      @click="printInvoice"
                    >
                      <v-icon>mdi-printer</v-icon>
                    </v-btn>
                  </v-col>

                  <div id="printable_invoice_content">
                    <v-row>
                      <v-col cols="6" sm="6" md="6" class="my-n2">
                        <v-img
                          lazy-src="img/logo-128.png"
                          max-height="150"
                          max-width="250"
                          src="img/logo-128.png"
                        ></v-img>
                      </v-col>

                      <v-col cols="6" sm="6" md="6" class="my-n2" v-if="editedItem.supplier">
                        <div><strong>{{ editedItem.supplier.company.company_name }}</strong></div>
                        <div>{{ editedItem.supplier.company.physical_location }}</div>
                        <div>{{ editedItem.supplier.phone }}</div>
                        <div>{{ editedItem.supplier.email }}</div>
                      </v-col>
                    </v-row>

                    <v-row class="my-2">
                      <v-col cols="6" sm="6" md="6" class="my-n2">
                        <h2>Bill To:</h2>
                        <div>Software Technologies Ltd.</div>
                        <div>P.O Box 17797 – 00500</div>
                        <div>7th Floor, West Point Building, Mpaka Road, Westlands-Nairobi.</div>
                        <div>+254 709 609000</div>
                        <div>info@stl-horizon.com</div>
                      </v-col>

                      <v-col cols="6" sm="6" md="6" class="my-n2">
                        <h2 class="text-center">INVOICE #{{ editedItem.id }}</h2>
                        <div>
                          <template>
                            <div>
                              <v-menu
                                v-model="menu3"
                                :close-on-content-click="true"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="invoiceDate"
                                    label="Invoice Date"
                                    prepend-icon="mdi-calendar"
                                    :rules="rules.required"
                                    readonly
                                    disabled
                                    v-bind="attrs"
                                    v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="invoiceDate"
                                  @input="menu3 = false"
                                ></v-date-picker>
                              </v-menu>
                            </div>
                          </template>
                        </div>
                        <div>
                          <template>
                            <div>
                              <v-menu
                                v-model="menu4"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="dueDate"
                                    :rules="rules.required"
                                    label="Due Date"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    disabled
                                    v-bind="attrs"
                                    v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="dueDate"
                                  @input="menu4 = false"
                                ></v-date-picker>
                              </v-menu>
                            </div>
                          </template>
                        </div>
                      </v-col>
                    </v-row>

                    <v-col cols="12" sm="12" md="12" class="my-2">
                      <h2>Invoice Items</h2>

                      <template>
                        <v-simple-table>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left">
                                  #
                                </th>
                                <th class="text-left">
                                  Name
                                </th>
                                <th class="text-left">
                                  Quantity
                                </th>
                                <th class="text-left">
                                  Price
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(quotation_item, i) in editedItem.items"
                                :key="i"
                              >
                                <td data-label="No.">{{ i + 1 }}</td>
                                <td>{{ quotation_item.requisition_item.name }}</td>
                                <td>{{ quotation_item.requisition_item.quantity }}</td>
                                <td>{{ invoiceCurrency.symbol }} {{ quotation_item.price }}</td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>

                              <tr>
                                <td></td>
                                <td></td>
                                <td><strong>Total Before Tax</strong></td>
                                <td><strong>{{ decimalDigits(subTotal) }}</strong></td>
                              </tr>
                                  
                              <tr>
                                <td></td>
                                <td></td>
                                <td>Discount ({{ discountRate }}%)</td>
                                <td><strong>{{ decimalDigits(discountTotal) }}</strong></td>
                              </tr>
                              <tr>
                                <td></td>
                                <td></td>
                                <td>Tax Total ({{ taxRate }}%)</td>
                                <td><strong>{{ decimalDigits(taxTotal) }}</strong></td>
                              </tr>
                              <tr>
                                <td></td>
                                <td></td>
                                <td><strong>Grand Total</strong></td>
                                <td><strong>{{ decimalDigits(grandTotal) }}</strong></td>
                              </tr>
                            </tfoot>
                          </template>
                        </v-simple-table>
                      </template>
                    </v-col>

                    <v-col cols="12" sm="12" md="12" class="my-2">
                      <v-alert
                        border="top"
                        colored-border
                        type="info"
                        elevation="2"
                        v-model="note"
                      >
                        <ol>
                          <li>Total payment due in 30 days.</li>
                          <li>Please include the invoice number on your cheque.</li>
                        </ol>
                      </v-alert>
                    </v-col>
                  </div>
              </v-container>
            </v-card-text>
      
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                class="text-capitalize mx-1"
                dark
                @click="closeInvoice"
              >
                Cancel <v-icon small>mdi-cancel</v-icon>
              </v-btn>
              <v-btn
                color="primary"
                dark
                class="text-capitalize mx-1"
                :loading="confirmLoading"
                @click="confirmInvoice"
              >
                Confirm Invoice <v-icon>mdi-content-save-outline</v-icon>
              </v-btn>
            </v-card-actions>
          </v-form>  
        </v-card>
      </v-dialog>
    </div>

    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="hee hee"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="800px"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content" id="printarea">
        <!-- PDF Content Here -->
      </section>
    </vue-html2pdf>
  </div>
</template>

<style>
.ck-editor__editable {
  min-height: 250px;
}
.in-editor .ck-toolbar {
  display: inline !important;
}
</style>
<script>
import QuotationService from "../../services/quotation.service";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import VueHtml2pdf from "vue-html2pdf";

export default {
  components: { VueHtml2pdf },
  data: () => ({
    name: "SupplierCategory",
    workflows: [],
    supplier_groups: [],
    selectRules: [(v) => !!v || "Select is required"],
    dialog_view: false,
    dialogSendToSuppliers: false,
    dialogGenerateInvoice: false,
    editor: ClassicEditor,
    editorConfig: {
      viewportTopOffset: 50,
      shouldNotGroupWhenFull: true,
      editorplaceholder: "Details here",
      toolbarCanCollapse: false,
      toolbar: {
        viewportTopOffset: 30,
        shouldNotGroupWhenFull: true,
      },
    },
    editorConfig2: {
      viewportTopOffset: 50,
      shouldNotGroupWhenFull: true,
      editorplaceholder: "Details here",
      toolbarCanCollapse: false,
      readOnly: true,
      toolbar: {
        viewportTopOffset: 30,
        shouldNotGroupWhenFull: true,
      },
    },

    loading: true,
    confirmLoading: false,
    items: [],
    search: "",
    editedIndex: -1,
    editedItem: {
      requisition: {
        title: ""
      },
      supplier: {
        name: "",
        company: {
          company_name: ""
        }
      },
    },
    defaultItem: {
      requisition: {
        title: ""
      },
      supplier: {
        name: "",
        company: {
          company_name: ""
        }
      },
    },
    remember: 0,
    timeout: 2000,
    dialog: false,
    valid: true,
    menu3: false,
    menu4: false,
    invoiceDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    dueDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    note: "",
    dialog_approve: false,
    dialog_reject: false,
    dialog_confirm: false,
    is_allowed: true,
    documents: [],
    receivedNote: {},
    receivedVoucher: {},
    
    rules: {
      select: [(v) => !!v || "An item should be selected"],
      select2: [(v) => v.length > 0 || "Atleast one item should be selected"],
      required: [
        (v) => !!v || "Field is required",
        (v) => (v && v.length >= 3) || "Min 3 characters",
      ],
      file: [
        (v) => !!v || "File is required",
        (v) => (v && v.length > 0) || "File is required",
      ],
    },
    headers: [
      {
        text: "#",
        align: "start",
        filterable: true,
        value: "id",
      },
      {
        text: "Requisition Title",
        value: "requisition.title",
      },
      {
        text: "Supplier Name",
        value: "supplier.name",
      },
      {
        text: "Quotation",
        value: "quotation",
      },
      {
        text: "Status",
        value: "status",
      },
      {
        text: "Actions",
        align: "center",
        value: "actions",
        sortable: false,
        width: "20%"
      },
    ],
    document_headers: [
      {
        text: "#",
        align: "start",
        filterable: true,
        value: "id",
      },
      {
        text: "Quotation Document",
        value: "attachment",
      },
      {
        text: "Delivery Note",
        value: "delivery",
      },
      {
        text: "Invoice",
        value: "invoice",
      },
      {
        text: "Receipt",
        value: "receipt",
      },
      {
        text: "Goods Received Note",
        value: "goods",
      },
      {
        text: "Payment Voucher",
        value: "voucher",
      },
    ],
    invoiceCurrency: {
      "symbol":"Ksh",
      "name":"Kenyan Shilling",
      "symbol_native":"Ksh",
      "decimal_digits":2,
      "rounding":0,
      "code":"KES",
      "name_plural":"Kenyan shillings"
    },
    taxRate: 16,
    discountRate: 0,
  }),
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    formTitle() {
      return this.editedIndex === -1 ? "New" : "Edit";
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    getLoading() {
      return this.loading;
    },
    subTotal() {
      var sum = 0;
      if(this.editedItem.items) {
        this.editedItem.items.forEach(item => {
          sum = sum += item.price
        });
      }
      
      return sum;
    },
    discountTotal() {
      var total = this.subTotal * (this.discountRate / 100);
      return total;
    },
    taxTotal() {
      var total = (this.subTotal - this.discountTotal) * (this.taxRate / 100);
      return total;
    },
    grandTotal() {
      var total = (this.subTotal - this.discountTotal) + this.taxTotal;
      return total;
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  created() {
    this.index();
  },
  methods: {
    trimText(text, length) {
      text.substring(0, length);
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    viewItemModal(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.getQuotationDocuments(this.editedItem);
      this.dialog_view = true;
    },
    approveItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog_approve = true;
    },
    rejectItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog_reject = true;
    },
    confirmItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog_confirm = true;
    },

    generateInvoiceModal(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.discountRate = this.editedItem.invoice.discount_rate;
      this.taxRate = this.editedItem.lpo.rate;
      this.dialogGenerateInvoice = true;
    },
    
    decimalDigits: function(value) {
        return this.invoiceCurrency.symbol + ' ' + value.toFixed(this.invoiceCurrency.decimal_digits);
    },

    printInvoice() {
      var printable_content = document.getElementById("printable_invoice_content");
      var pdf = document.getElementById("printarea");
      pdf.innerHTML = printable_content.innerHTML;
      this.$refs.html2Pdf.generatePdf();
    },


    // search
    filterText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleLowerCase().indexOf(search) !== -1
      );
    },
    handleLogout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    truncate(str, n) {
      return str.length > n ? str.substr(0, n - 1) + "..." : str;
    },
    index() {
      return QuotationService.approver_index(this.user).then(
        (response) => {
          if (response.status == 200) {
            this.items = response.data.data;
            this.loading = false;
          } else {
            this.items = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    viewItem(item) {
      this.$router.push("/suppliers-view/" + item.id);
    },

    close() {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.dialog_approve = false;
      this.dialog_reject = false;
      this.dialog_confirm = false;
    },

    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    async handleFileUpload(file) {
      console.log("uploading...");
      await this.getBase64(file).then(
        (data) => (this.editedItem.attachment = data)
      );
    },

    async handleReceivedNoteUpload(file) {
      console.log("uploading...");
      let formData = {};
      formData["applicant_id"] = this.user.id;
      formData["document_id"] = 2;
      await this.getBase64(file).then((data) => (formData["content"] = data));
      this.file = null;
      this.receivedNote = formData;
    },

    async handleVoucherUpload(file) {
      console.log("uploading...");
      let formData = {};
      formData["applicant_id"] = this.user.id;
      formData["document_id"] = 4;
      await this.getBase64(file).then((data) => (formData["content"] = data));
      this.file = null;
      this.receivedVoucher = formData;
    },

    checkStatus(item) {
      if(item.approved == 1) {
        return false;
      }

      return true;
    },

    closeInvoice() {
      this.dialogGenerateInvoice = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    confirmInvoice() {
      this.loading = true;
      this.sendLoading = true;
      this.$refs.form.validate();
      
      if (this.valid) {
          // create
          QuotationService.confirm_invoice(this.editedItem).then(
            (response) => {
              if (response.status == 200) {
                this.$store.dispatch("alert/success", "LPO Sent Successfully");
                console.log(this.editedItem);
                this.loading = false;
                this.confirmLoading = false;
                this.index();
                this.closeInvoice();
              } else {
                this.$store.dispatch("alert/error", response.data.message);
                this.index();
              }
            },
            (error) => {
              if (error.response.status == 422) {
                this.$store.dispatch("alert/error", error.response.data.message);
                this.loading = false;
                this.confirmLoading = false;
                this.index();
              }
              if (error.response.status == 401) {
                this.handleLogout();
                this.loading = false;
                this.confirmLoading = false;
                this.index();
              }
              console.log(error);
              this.$store.dispatch("alert/error", error.response.data.message);
            }
          );
      }
    },

    generateLPO() {
      this.$router.push('generate-lpo');
    },

    getQuotationDocuments(item) {
      this.loading = true;
      return QuotationService.get_documents(item).then(
        (response) => {
          if (response.status == 200) {
            this.documents = response.data.data;
            this.loading = false;
          } else {
            this.documents = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.documents = [];
          console.log(error);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },

    approveQuotation() {
      this.loading = true;
      this.$refs.form.validate();
      if (this.valid) {
        Object.assign(this.items[this.editedIndex], this.editedItem);
        console.log(this.editedItem);
        QuotationService.approve(this.editedItem).then(
          (response) => {
            if (response.status == 200) {
              this.$store.dispatch("alert/success", response.data.message);
              this.loading = false;
              this.index();
              this.close();
            } else {
              this.$store.dispatch("alert/error", response.data.message);
              this.index();
              this.loading = false;
            }
          },
          (error) => {
            if (error.response.status == 422) {
              this.$store.dispatch("alert/error", error.response.data.message);
              this.loading = false;
              this.index();
            }
            if (error.response.status == 401) {
              this.handleLogout();
              this.loading = false;
              this.index();
            }
            console.log(error);
            this.$store.dispatch("alert/error", error.response.data.message);
          }
        );
      } else {
        this.loading = false;
        this.index();
      }
    },

    rejectQuotation() {
      this.loading = true;
      this.$refs.form.validate();
      if (this.valid) {
        Object.assign(this.items[this.editedIndex], this.editedItem);
        console.log(this.editedItem);
        QuotationService.reject(this.editedItem).then(
          (response) => {
            if (response.status == 200) {
              this.$store.dispatch("alert/success", response.data.message);
              this.loading = false;
              this.index();
              this.close();
            } else {
              this.$store.dispatch("alert/error", response.data.message);
              this.index();
              this.loading = false;
            }
          },
          (error) => {
            if (error.response.status == 422) {
              this.$store.dispatch("alert/error", error.response.data.message);
              this.loading = false;
              this.index();
            }
            if (error.response.status == 401) {
              this.handleLogout();
              this.loading = false;
              this.index();
            }
            console.log(error);
            this.$store.dispatch("alert/error", error.response.data.message);
          }
        );
      } else {
        this.loading = false;
        this.index();
      }
    },

    confirmDelivery() {
      this.loading = true;
      this.$refs.form.validate();
      if (this.valid) {
        Object.assign(this.items[this.editedIndex], this.editedItem);
        this.editedItem.goods_received = this.receivedNote;
        this.editedItem.voucher = this.receivedVoucher;

        console.log(this.editedItem);
        QuotationService.confirm_delivered(this.editedItem).then(
          (response) => {
            if (response.status == 200) {
              this.$store.dispatch("alert/success", response.data.message);
              this.loading = false;
              this.index();
              this.close();
            } else {
              this.$store.dispatch("alert/error", response.data.message);
              this.index();
              this.loading = false;
            }
          },
          (error) => {
            if (error.response.status == 422) {
              this.$store.dispatch("alert/error", error.response.data.message);
              this.loading = false;
              this.index();
            }
            if (error.response.status == 401) {
              this.handleLogout();
              this.loading = false;
              this.index();
            }
            console.log(error);
            this.$store.dispatch("alert/error", error.response.data.message);
          }
        );
      } else {
        this.loading = false;
        this.index();
      }
    },
  },
};
</script>
