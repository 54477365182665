// Data sevice
import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.VUE_APP_API_URL;

class QuotationService {
    index() {
        return axios.get(API_URL + 'quotation', { headers: authHeader() })
    }
    approver_index(user) {
        return axios.get(API_URL + 'quotations-for-approver/' + user.id, { headers: authHeader() })
    }
    supplier_index(user) {
        return axios.get(API_URL + 'quotations-for-supplier/' + user.id, { headers: authHeader() })
    }
    show(id) {
        return axios.get(API_URL + 'quotation/'+id, { headers: authHeader() })
    }
    store(item) {
        return axios.post(API_URL + 'quotation', item, { headers: authHeader() })
    }
    update(item) {
        return axios.put(API_URL + 'quotation/'+item.id, item, { headers: authHeader() })
    }
    delete(item) {
        return axios.delete(API_URL + 'quotation/'+item.id, { headers: authHeader() })
    }
    approve(item) {
        return axios.post(API_URL + 'quotation-approve/'+item.id, item, { headers: authHeader() })
    }
    reject(item) {
        return axios.post(API_URL + 'quotation-reject/'+item.id, item, { headers: authHeader() })
    }
    delivered(item) {
        return axios.post(API_URL + 'quotation-delivered/'+item.id, item, { headers: authHeader() })
    }
    get_documents(item) {
        return axios.get(API_URL + 'get-quotation-documents/' + item.id, { headers: authHeader() })
    }
    confirm_delivered(item) {
        return axios.post(API_URL + 'confirm-delivered/'+item.id, item, { headers: authHeader() })
    }
    addApplicantQuotationDocument(resource) {
        return axios.post(API_URL + 'applicant-quotation-document', resource, { headers: authHeader() })
    }
    awarded_quotations() {
        return axios.get(API_URL + 'awarded-quotations', { headers: authHeader() })
    }
    send_lpo(item) {
        return axios.post(API_URL + 'send-lpo/' + item.id, item, { headers: authHeader() })
    }
    send_invoice(item) {
        return axios.post(API_URL + 'send-invoice/' + item.id, item, { headers: authHeader() })
    }
    confirm_invoice(item) {
        return axios.post(API_URL + 'confirm-invoice/' + item.id, item, { headers: authHeader() })
    }
}

export default new QuotationService()